import storageService from '../services/storage.js';
import couponService from '../services/coupon.js';

export default function singleMedicationChoice() {
  const singleProductBtn = document.querySelector('#landing-productbtn');

  listenForSingleProductBtn();

  function listenForSingleProductBtn() {
    singleProductBtn.addEventListener('click', item => {
      storageService().tests = [{ name: singleProductBtn.dataset.name, price: singleProductBtn.dataset.price * 100 }];
      couponService().applyDefaultCoupon();
      window.location.href = '/uti-pharmacy';
    });
  }
}
